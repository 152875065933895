@import "_secondary";

header, .conversion, .sns, #policy, footer {
  display: none;
}

.flow-step__image {
  img, svg {
    height: 50px;
  }
}
