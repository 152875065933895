//
// 変数
//
@import "_variables";

$PC_max: 1440px;
$PC_min: 1006px;

//
// 関数
//
@import "_mixins";



/* ------------------------------------------------------------------------
  ★module
--------------------------------------------------------------------------*/

h3 {
  font-family: $ff-main;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
  color: $c-brown;
  text-align: left;
  display: flex;
  line-height: 1.4;
  &::after {
    border-bottom: 1px dotted $c-brown;
    content: "";
    flex-grow: 1;
    margin-left: 20px;
  }
}

@include mq('PC') {
  h3 {
    font-size: 4.6rem;
  }
}

@include mq('SP') {
  h3 {
    //margin-top: 40px;
    font-size: 3.6rem;
    font-weight: 500;
  }
}

h4,h5,h6 {
  line-height: 1.4;
}

.button {
  display: inline-block;
  width: 100%;
  background: url(/assets/images/top/pattern_transparent.png) repeat left top;
  font-size: 2rem;
  font-family: $ff-main;
  color: $c-white;
  text-align: center;
  border: 1px solid $c-white;
}
@include mq('PC') {
  .button {
    min-width: 240px;
    padding: 40px 0;
    font-size: 18px;
  }
}


/* ------------------------------------------------------------------------
  ★sns
--------------------------------------------------------------------------*/

.sns.pc {
  .sns__item {
    //border: 1px solid $c-black;
  }
}

@include mq('PC') {
  .sns.pc {
    position:absolute;
    top: 764px;
    bottom: auto;
    .sns__item {
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.18);
      &#fb {
        @include ico_fb(40px, 40px);
        background-size: 40px 20px;
        background-position: center center;
      }
      &#tw {
        @include ico_tw(40px, 40px);
        background-size: 18px 20px;
        background-position: center center;
      }
      &#in {
        @include ico_in(40px, 40px);
        background-size: 18px 18px;
        background-position: center center;
      }
    }
  }
}


/* ------------------------------------------------------------------------
  ★hero
--------------------------------------------------------------------------*/

%hero {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  h2 {
    text-align: center;
    font-family: $ff-all;
    color: $c-white;
    position: relative;
    z-index: $zi-level3;
    .hero-ttl__head {
      display: block;
    }
    .hero-ttl_sub {
      display: block;
      font-weight: 600;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    @include cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: $zi-level2;
  }
  #hero_movie {
    position: absolute;
    z-index: $zi-level1;
  }
  .modal__movie {
    display: block;
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: $zi-level3;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after {
      content: "";
      display: block;
      @include play(48px, 48px, "white");
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .modal__movie--nocookie {
      display: none;
    }
  }
  @include mq('PC') {
    height: 680px;
    h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      font-size: 7.8rem;
      font-weight: 550;
      letter-spacing: 0.06em;
      transform: translate(-50%, -50%);
      .hero-ttl__head {
        margin-bottom: 30px;
        font-size: 4rem;
      }
      .hero-ttl_sub {
        margin-top: 30px;
        font-size: 4rem;
      }
    }
    .modal__movie {
      transition: $link_transition;
      &:hover {
        opacity: $hover_opacity;
      }
    }
  }
  @include mq('SP') {
    background-size: auto 100%;
    height: 50vh;
    text-align: center;
    overflow: hidden;
    h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      width: calc(100% - 40px);
      font-size: 4.6rem;
      letter-spacing: 0.05em;
      line-height: 1.4;
      transform: translate(-50%, -50%);
      .hero-ttl__head {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }
      .hero-ttl_sub {
        margin-top: 10px;
        font-size: 1.6rem;
        letter-spacing: 0.03em;
      }
    }
    .modal__movie {
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
    .modal__movie {
      &::before {
        display: none;
      }
      img{
        opacity: 0;
      }
    }
  }
  @media screen and (orientation: landscape) {
    background-size: cover;
  }
}

/* ------------------------------------------------------------------------
  ★main
--------------------------------------------------------------------------*/

main {
  display: block;
  background: rgb(248,247,242);
  background: -moz-linear-gradient(180deg, rgba(248,247,242,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(248,247,242,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(248,247,242,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f7f2",endColorstr="#ffffff",GradientType=1);
}

@include mq('SP') {
  main {
    padding-bottom: 90px;
  }
}

.contents__textlink {
  text-decoration: underline;
}

@include mq('PC') {
  .contents__textlink {
    transition: color 0.2s ease;
    &:hover {
      color: #c00;
    }
  }
}
