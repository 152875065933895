$breakpoint: (
  'PC': 'screen and (min-width: 768px)',
  'TAB': 'screen and (min-width: 768px) and (max-width: 1366px)',
  'SP': 'screen and (max-width: 767px)'
);

// color
$c-black: #333333;
$c-black_2: #434343;
$c-white: #ffffff;
$c-gold : #a59c53;
$c-gray : #434343;
$c-brown: #9a7c5f;
$c-red: #c41407;

$c-gold_hover: #99914d;

// font
$ff-all : 'Oswald',-apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Kaku Gothic ProN","游ゴシック体",YuGothic,"游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic","メイリオ",Meiryo,sans-serif;
$ff-main: 'Oswald', sans-serif;

// z-index

$zi-level1: 1;
$zi-level2: 5;
$zi-level3: 10;
$zi-level4: 50;
$zi-level5: 100;
$zi-level6: 500;
$zi-level7: 1000;

// shadow
$b-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.08);
$f-shadow: 0px 12px 20px rgba(0, 0, 0, 0.08);

// transition
$link_transition: opacity 0.2s ease;

// hover
$hover_opacity: 0.7;
